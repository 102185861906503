import $ from 'jquery';

$(function () {
  let currentHeight = 0;

  // 離脱防止モーダル表示
  const leaveModal = () => {
    const posi = $(window).scrollTop() || 0; //モーダル表示中、背景がスクロールされないよう固定
    currentHeight = posi;
    $('.leaveModal, .leave_overlay').show();
    $('html').addClass('scrollNone');
    $('html').css({ //モーダルを表示させた位置で背景を固定
      width: '100%',
      position: 'fixed',
      top: -1 * posi
    });
  };

  

  history.pushState(null, '', null);
  $(window).one('popstate', () => leaveModal());
  $('a').off('popstate');

  $('.modalArea__close,#lean_overlay, .leave_overlay').on('click', () => {
    $('.modalArea,#lean_overlay,.leave_overlay').hide();
    $('html').removeClass('scrollNone');
    $('html').attr('style', ''); //背景スクロール固定解除
    $('html, body').prop({scrollTop: currentHeight}); //位置を戻す
    return false;
  });
  
  $('a[href^="#"]').click(function () {
    const href = $(this).attr('href') || '';
    const target = $(href == '#' || href == '' ? 'html' : href);
    const position = (target.offset()?.top || 0)
    $('html,body').animate({scrollTop:position}, 800, 'swing')
    return false
  });
});